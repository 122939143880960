@import "shared/themes/breakpoints";

.page {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	
	background: url("/../public/main-page-background.webp") center / cover no-repeat;
	background-attachment: fixed;

	.fairy {
		--size: 130px;
		position: fixed;
		top: 61%;
		left: 82%;
		transform: translate(-50%, -50%);
		width: var(--size, 150px);
		height: var(--size, 150px);
		object-fit: cover;
		cursor: pointer;
		-moz-transition: all 1s ease-out;
		-o-transition: all 1s ease-out;
		-webkit-transition: all 1s ease-out;
		transition: all 1s ease-out;
	}
	
	.fairy:hover {
		-webkit-transform: translate(-50%, -50%) scale(1.1);
		-moz-transform: translate(-50%, -50%) scale(1.1);
		-o-transform: translate(-50%, -50%) scale(1.1);
		transform: translate(-50%, -50%) scale(1.1);
	}
}

.container {
	width: 100%;
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 1 auto;
	overflow-y: auto;
	overflow-x: hidden;
	mask-border-mode: alpha;
	mask-image: linear-gradient(to bottom, transparent, #000 2rem, #000 calc(100% - 2rem), transparent);

	.logo {
		width: 100%;
		max-width: 660px;
		display: flex;
		justify-content: center;
		-webkit-animation: slideIn2 0.7s forwards;
		-moz-animation: slideIn2 0.7s forwards;
		animation: slideIn2 0.7s forwards;
	
		img {
			width: 100%;
			max-width: 85%;
			object-fit: cover;
			pointer-events: none;
			user-select: none;
		}
	}

	.content {
		width: 100%;
		-webkit-animation: slideIn 0.7s forwards;
		-moz-animation: slideIn 0.7s forwards;
		animation: slideIn 0.7s forwards;
		max-width: 660px;
	
		p {
			font-family: AllodsWest;
			font-size: var(--h6-desktop-size);
			font-weight: 400;
			font-style: normal;
			line-height: 120%;
			text-align: center;
			color: #FFF;
	
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
}

@-webkit-keyframes slideIn {
	0% {
		transform: translateX(-900px);
	}
	100% {
		transform: translateX(0);
	}
}
@-moz-keyframes slideIn {
	0% {
		transform: translateX(-900px);
	}
	100% {
		transform: translateX(0);
	}
}
@keyframes slideIn {
	0% {
		transform: translateX(-900px);
	}
	100% {
		transform: translateX(0);
	}
}

@-webkit-keyframes slideIn2 {
	0% {
		transform: translateX(900px);
	}
	100% {
		transform: translateX(0);
	}
}
@-moz-keyframes slideIn2 {
	0% {
		transform: translateX(900px);
	}
	100% {
		transform: translateX(0);
	}
}
@keyframes slideIn2 {
	0% {
		transform: translateX(900px);
	}
	100% {
		transform: translateX(0);
	}
}

@include smartBreakpointByType(max, lg) {
	.container {
		max-width: 94%;
	}
}

@media (992px >= width >= 768px) {
	.container {
		max-width: 80%;
	}
}