@mixin scrollbar () {
	$scrollbar-size: 0.5rem;
	$scrollbar-track: #0d2341;
	$scrollbar-thumb: #2a5a9b;

	&::-webkit-scrollbar {
		height: $scrollbar-size;
		width: $scrollbar-size;
		cursor: pointer;
	}

	&::-webkit-scrollbar-track {
		background-color: rgba($scrollbar-track, 0.5);
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba($scrollbar-thumb, 0.5);
		border-radius: calc(0.5 * $scrollbar-size);
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-thumb {
		transition: background-color 0.5s ease-in-out;
	}

	&:hover {
		&::-webkit-scrollbar-track {
			background-color: rgba($scrollbar-track, 1);
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba($scrollbar-thumb, 1);
		}
	}
}