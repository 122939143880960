@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");

@font-face {
	font-family: "AllodsWest";
	font-style: normal;
	font-weight: normal;
	src: local(AllodsWest),
 url("../public/fonts/allods_west.ttf") format("truetype");
	font-display: block;
}
body {
	margin: 0;
	line-height: normal;
	overflow: hidden;
	min-height: 100vh;
}
#root {
	min-height: 100vh;
}
:root {
	/* fonts */
	--h6-desktop: AllodsWest;
	--text-normal: "Open Sans";

	/* font sizes */
	--h1-desktop-size: clamp(24px, 2.5vw, 48px);
	--h6-desktop-size: clamp(14px, 2vw, 20px);
	--text-normal-size: 16px;

	/* Colors */
	--light-100: #f0f3ff;
	--secondary-100: #fcc600;
	--primary-100: #0d2341;
	--primary-60: rgba(13, 35, 65, 0.6);

	/* Gaps */
	--gap-9xs: 4px;
	--gap-13xl: 32px;
	--gap-5xs: 8px;
	--gap-29xl: 48px;

	/* Paddings */
	--padding-base: 16px;
	--padding-29xl: 48px;
	--padding-3xs: 10px;
	--padding-xl: 20px;

	/* border radiuses */
	--br-10xs: 3px;

	/* Element sizes */
	--section-mob-height: 500px;
	--header-mob-height: max(120px, 14vh);
	--divider-height: 25px;
	--mute-button-size: 48px;
	--change-character-arrow-size: 70px;
}

@media (992px >= width) {
	:root {
		--mute-button-size: 32px;
		--change-character-arrow-size: 35px;
	}
}

@media (width > 1920px) {
	:root {
		--h1-desktop-size: clamp(48px, 2.5vw, 54px);
		--h6-desktop-size: clamp(20px, 2vw, 130%);
	}
}

* {
	box-sizing: border-box;
}

a {
	color: white;
	text-decoration: none;
}